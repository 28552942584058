import { ReceiptService } from "./../../../shared/services/receipt.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ChartAccountService } from "src/app/services/chart-account.service";
import { ProfilesService } from "src/app/services/profiles.service";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  readonly TRANSACTION_TYPES = {
    JOURNALENTRY: 1,
    OBLIGATION: 3,
    DEPOSIT: 5,
    PAYMENT: 6,
    FUNDSTRANSFER: 7,
    CURRENCYEXCHANGE: 8,
  };

  showMathWidget = false;
  loading = false;
  transactions: any[];

  currencies;
  profiles;
  paymentAccounts;
  accounts;

  statuses = [
    { id: 1, name: "Verified" },
    { id: 2, name: "Unverified" },
  ];
  types = [
    { id: 5, name: "Deposit" },
    { id: 6, name: "Payment" },
    { id: 1, name: "Journal Entry" },
    { id: 7, name: "Funds Transfer" },
    { id: 8, name: "Currency Purchasing" },
    { id: 3, name: "Obligation Deposit" },
    // { id: 0, name: "Assets" },
    // { id: 1, name: "Liabilities" },
    // { id: 1, name: "Income" },
    // { id: 1, name: "Expense" },
    // { id: 1, name: "Equity" },
  ];
  filters = {
    status: null,
    personId: null,
    transactionTypeId: null,
    currencyId: null,
    paymentAccountId: null,
    accountId: null,
    description: null,
    transactionNo: null,
    fromDate: null,
    toDate: null,
    sortBy: "transactionEntry.dateTime",
    sortOrder: "desc",
    pageNumber: 1,
    pageSize: 10,
    entryNumber: null,
    fromAmount: null,
    toAmount: null,
  };
  paging = {
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    recordCount: 0,
  };

  paymentSum;
  depositSum;
  difference;

  constructor(
    private http: HttpClient,
    private receiptService: ReceiptService,
    private chartAccountService: ChartAccountService,
    private profilesService: ProfilesService
  ) {
    this.loadTransactions();
    this.loadDropDownsData();
  }
  ngOnInit() {
    // window.onclick = function (event) {
    //   if (!event.target.matches("a.dropdown-toggle")) {
    //     var dropdowns = document.getElementsByClassName("dropdown");
    //     for (let i = 0; i < dropdowns.length; i++) {
    //       var openDropdown = dropdowns[i];
    //       if (openDropdown.classList.contains("open")) {
    //         openDropdown.classList.remove("open");
    //       }
    //     }
    //   }
    // };
  }

  toggleMathWidget(){
    this.showMathWidget = !this.showMathWidget;
  }

  applySortClass(columName: string) {
    if (this.filters.sortBy.toLowerCase() !== columName.toLowerCase()) {
      return null;
    }

    const sortingClass = {};
    sortingClass[this.filters.sortOrder] = true;
    return sortingClass;
  }

  applySort(columnName: string) {
    if (this.filters.sortBy.toLowerCase() === columnName.toLowerCase()) {
      this.filters.sortOrder =
        this.filters.sortOrder === "asc" ? "desc" : "asc";
    } else {
      this.filters.sortBy = columnName;
      this.filters.sortOrder = "asc";
    }

    this.filters.pageNumber = 1;
    this.loadTransactions();
  }

  applyFilters() {
    this.filters.pageNumber = 1;
    this.loadTransactions();
  }

  loadTransactions() {
    this.loading = true;
    const params = this.getFiltersQueryString();

    this.http.get("api/transactions?" + params.toString()).subscribe(
      (response: any) => {
        this.transactions = response.records;

        this.depositSum = response.depositSum;
        this.paymentSum = response.paymentSum;
        this.difference = response.difference;

        this.paging.pageNumber = response.pageNumber;
        this.paging.pageSize = response.pageSize;
        this.paging.pageCount = response.pageCount;
        this.paging.recordCount = response.recordCount;
      },
      () => {},
      () => (this.loading = false)
    );
  }

  private getFiltersQueryString() {
    const params = new URLSearchParams();

    for (const key of Object.keys(this.filters)) {
      const value = this.filters[key];
      if (value) {
        params.set(key, this.filters[key]);
      }
    }

    return params.toString();
  }

  loadDropDownsData() {
    this.chartAccountService.currencies$.subscribe((currencies) => this.currencies = currencies);
    this.profilesService.profiles$.subscribe((profiles) => this.profiles = profiles);
    this.chartAccountService.paymentAccounts$.subscribe((accounts) => this.paymentAccounts = accounts);
    this.chartAccountService.accounts$.subscribe((accounts) => this.accounts = accounts);
  }

  handlePageSizeChange() {
    this.filters.pageNumber = 1;
    this.loadTransactions();
  }

  hasPreviousPage() {
    return this.paging.pageNumber > 1;
  }

  hasNextPage() {
    return this.paging.pageNumber < this.paging.pageCount;
  }

  moveToPreviousPage() {
    if (this.hasPreviousPage()) {
      this.filters.pageNumber = this.filters.pageNumber - 1;
      this.loadTransactions();
    }
  }

  moveToNextPage() {
    if (this.hasNextPage()) {
      this.filters.pageNumber = this.filters.pageNumber + 1;
      this.loadTransactions();
    }
  }

  print(transactionId, transactionTypeId, isEnglish = true) {
    this.receiptService.printReceipt(
      transactionId,
      transactionTypeId,
      isEnglish
    );
  }
}
